<template>
  <div>
    <vs-popup class="holamundo"  :title="isEdit ? $t('customPricings.edit') + ' | ' + pricingToEdit.title : $t('customPricings.add')" :active.sync="pricingFormActive" @close="closePopup">
            <div class="w-full mb-5" v-if="isSeasonal">
                <vs-input :label="$t('customPricings.title')" placeholder="مثال: خصومات موسم رمضان" v-model="pricingToEdit.title" class="mt-5 w-full" autocomplete="off"/>
            </div>
            <v-date-picker
                class="w-full"
                v-model="bookingsDateRange"
                mode="date"
                is-range
                color="orange"
                :masks="dateFormat"
                v-if="isSeasonal"
            >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div class="w-full flex flex-wrap justify-between">
                        <vs-input
                        :label="$t('customPricings.startDate')"
                        class="flex-grow py-1 bg-gray-100 border rounded w-1/2"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        icon="today"
                        />

                        <vs-input
                        :label="$t('customPricings.endDate')"
                        class="flex-grow py-1 bg-gray-100 border rounded w-1/2"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        icon="event"
                        />
                    </div>
                </template>
            </v-date-picker>
            <div class="w-full mt-5" v-if="isSeasonal">
                <div class="w-full" v-if="pricingToEdit.pricing_type == 'fixed'">
                    <vs-input type="number" :label="$t('customPricings.basePrice')" v-model="pricingToEdit.base_price" class="mt-5 w-full" autocomplete="off"/>
                </div>
                <div class="w-full flex flex-wrap justify-around" v-if="(pricingToEdit.pricing_type == 'variable' ||pricingToEdit.pricing_type == 'per_person')">
                    <vs-input v-for="guests_input in guests_inputs" :key="guests_input.key" :class="guestsInputStyle" :label="guests_input.name" v-model="pricingToEdit[guests_input.key]" type="number" autocomplete="off"/>
                </div>

            </div>
            <div class="w-full mt-5">
                <div class="mt-2 w-full flex flex-wrap justify-between" v-for="(day, index) in pricingToEdit.days" :key="index">
                    <div class="w-1/3 flex flex-wrap">
                        <vs-checkbox color="warning" class="m-0 mt-10" v-model="day.pivot.is_visible"></vs-checkbox>
                        <vs-input readonly :label="$t('customPricings.day')" :value="day.arabic_name" class="mt-5 w-2/3" autocomplete="off"/>
                    </div>

                    <div class="w-2/3" v-if="pricingToEdit.pricing_type == 'fixed'">
                        <vs-input type="number" :label="$t('customPricings.basePrice')" :placeholder="`السعر الحالي: ${option.price}`" v-model="day.pivot.base_price" class="mt-5 w-full" :disabled="!day.pivot.is_visible" autocomplete="off"/>
                    </div>
                    <div class="w-2/3 flex flex-wrap justify-around" v-if="(pricingToEdit.pricing_type == 'variable' ||pricingToEdit.pricing_type == 'per_person')">
                        <vs-input v-for="guests_input in guests_inputs" :key="guests_input.key" :class="guestsInputStyle" :label="guests_input.name" v-model="day.pivot[guests_input.key]" type="number" :disabled="!day.pivot.is_visible" autocomplete="off"/>
                    </div>
                </div>

            </div>
            <div class="w-full mt-10">
                <vs-button v-if="isEdit && pricingToEdit.id" icon="edit" @click="submitPricingForm">{{ $t("edit")}}</vs-button>
                <vs-button v-else icon="done" @click="submitPricingForm">{{ $t("Add")}}</vs-button>
            </div>
    </vs-popup>
  </div>
</template>

<script>
import { en, ar } from 'vuejs-datepicker/src/locale'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
      Datepicker,
      vSelect,
  },
  props: {
    pricingFormActive: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isSeasonal: {
      type: Boolean,
      default: false
    },
    currentPricing: {
        type: Object,
        default: () => {}
    },
    option: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
        pricingToEdit: {},
        langAr: ar,
        langEn: en,
        bookingsDateRange: {
            start: moment().startOf('month').toDate(),
            end: moment().endOf('month').toDate(),
        },
        dateFormat: {
            input: 'YYYY-MM-DD'
        },
        guests_inputs: [],
        guestsInputStyle: '',
        validated: false,

    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    bookingOption () {  // get the clicked booking option
        return this.$store.state.dataList.bookingOption;
    },
    daysList() {
        return this.$store.state.general.daysList
    },
    eventTypesList () {
        return this.bookingOption.event_types
    },
  },
  created () {
      this.$store.dispatch('dataList/getBookingOption', {venue: this.currentVenueId, option_id: this.$route.params.option_id});
      this.$store.dispatch('general/getDaysList');

  },
  mounted() {

  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },

    setupBookingDateFormats(){
      // setup the dates formats
      if(this.isSeasonal) {
          this.pricingToEdit.start_date = moment(this.bookingsDateRange.start).format('yyyy-MM-DD')
          this.pricingToEdit.end_date = moment(this.bookingsDateRange.end).format('yyyy-MM-DD')
      } else {
          this.pricingToEdit.start_date = null
          this.pricingToEdit.end_date = null
      }

    },
    validateForm() {
        let message = '';
        if(!this.pricingToEdit.title) { // validate title
            this.validated = false;
            message += this.$t('customPricings.validation.TitleRequired') + '<br/>';

        }
        if(this.isSeasonal) { // validate base pricing [fixed and variable]
            if(this.pricingToEdit.pricing_type != 'fixed') {
                this.guests_inputs.forEach(input => {
                    if(!this.pricingToEdit[input.key]) {
                        this.validated = false;
                        message += this.$t('customPricings.validation.VariableBasePriceRequired')+ ' ' + input.name + '<br/>';
                    }
                });
            } else {
                if(!this.pricingToEdit.base_price) {
                    this.validated = false;
                    message += this.$t('customPricings.validation.BasePriceRequired')+'<br/>';
                }
            }
        }
        // validate days
        this.pricingToEdit.days.forEach(day => {
            if(day.pivot.is_visible) { // check if the day is selected
                if(this.pricingToEdit.pricing_type != 'fixed') {
                    this.guests_inputs.forEach(input => {
                        if(!day.pivot[input.key]) {
                            this.validated = false;
                            message += this.$t('customPricings.validation.VariableDayPriceRequired')+ ' ' + input.name + ' ' +this.$t('customPricings.validation.DayName')+ ' ' + day.arabic_name+'<br/>';
                        }
                    });
                } else {
                    if(!day.pivot.base_price) {
                        this.validated = false;
                        message += this.$t('customPricings.validation.FixedDayPriceRequired')+ ' ' + input.name + ' ' +this.$t('customPricings.validation.DayName')+ ' ' + day.arabic_name+'<br/>';
                        // break;
                    }
                }
            }
        });

        if(message) {
            this.$vs.notify({
                color:'danger',
                title: this.$t('error'),
                text: message
            });
            this.$store.dispatch('loader/loaderOff');
            return;
        }
        this.validated = true;
    },
    removeUnusedDays() {
        let remainingDays = [];
        this.pricingToEdit.days.forEach((day, index) => {
            if(day.pivot.is_visible) {
                remainingDays.push(day);
            }
        });
        this.pricingToEdit.days = remainingDays
    },
    submitPricingForm() {

        this.$store.dispatch('loader/loaderOn')
        this.setupBookingDateFormats();
        this.validateForm();
        if(!this.validated)
            return
        this.removeUnusedDays();
        // send the request
        let action = (this.isEdit && this.pricingToEdit.id) ? 'customPricings/updateCustomPricing' : 'customPricings/addCustomPricing';
        let request = this.pricingToEdit;
        request.days = this.pricingToEdit.days;
        this.$store.dispatch(action, {venueId: this.currentVenueId, optionId: this.bookingOption.id, pricingId: this.pricingToEdit.id ? this.pricingToEdit.id : null, request: request})
            .then(response => {
            this.$vs.notify({
                color:'success',
                title: this.isEdit ? this.$t('updateDone') : this.$t('addedDone'),
                text: response.data.success_message
            })
            this.$emit('closePopup');
            this.$root.$emit('fetchCalendarData');
            })
            .catch(function(error){
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: error || this.isEdit ? 'عذرًا لم نستطع انشاء حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا' : 'عذرًا لم نستطع تعديل الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
                })
            console.error(error)
            this.error = error;
            }.bind(this))
            .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    modifyGuestsInputs() {
        this.guests_inputs = JSON.parse(JSON.stringify(this.eventTypesList[0].guests_inputs));
        this.guests_inputs.forEach((input, index) => {
            let key = '';
            let name = '';
            if(input.key == 'men_count') {
                name = input.name == 'عدد الرجال' ? this.$t('customPricings.manPrice') : input.name;
                key = 'man_price';
            } else if(input.key == 'women_count') {
                name = input.name == 'عدد النساء' ? this.$t('customPricings.womanPrice') : input.name;
                key = 'woman_price';
            } else {
                name = input.name == 'عدد الأشخاص' ? this.$t('customPricings.peoplePrice') : input.name;
                key = 'people_price';
            }
            this.guests_inputs[index] = {
                key: key,
                name: name
            }
        });
        if(this.guests_inputs.length == 1) {
            this.guestsInputStyle = 'mt-5 w-full'
        } else {
            this.guestsInputStyle = 'mt-5 pl-1 w-1/'+this.guests_inputs.length
        }
    },
  },
  watch: {
    currentPricing: function (val) {
        val = JSON.parse(JSON.stringify(val));
        this.pricingToEdit = val;
        this.bookingsDateRange = {
            start : val.start_date,
            end : val.end_date,
        }
        this.modifyGuestsInputs();
    },
  }

}
</script>

<style>

</style>
