<template>
    <div>
        <vs-popup v-if="selectedPricing" class="holamundo small-popup-25"  :title="selectedPricing.title" :active.sync="previewPricingActive">  
            <h4 class="mb-5">{{$t('customPricings.calendar.todayPricings')}}:</h4>
            <vs-collapse type="margin" class="p-0 w-full">
                <vs-collapse-item icon-arrow="arrow_downward" v-if="selectedPricing.originalEvent">
                    <div slot="header">
                        <div class="vx-col p-0 w-full flex flex-wrap justify-between pr-5">
                            <h3 class="text-success">{{selectedPricing.originalEvent.display_title}}</h3>
                            <h3 class="text-success">{{selectedPricing.originalEvent.price}} {{$t("SAR")}}</h3>
                        </div>
                        
                    </div>
                    <div class="flex flex-wrap mb-5">

                        <div class="vx-col p-0 w-full flex flex-wrap justify-between">
                            <h4>{{$t('customPricings.pricingType'+selectedPricing.originalEvent.custom_pricing.pricing_type)}}</h4>
                            <vs-chip class="font-medium truncate" color="success">{{$t('customPricings.appliedPricing')}}</vs-chip>
                        </div>
                        <div class="vx-col w-full flex flex-wrap mt-2" v-if="selectedPricing.originalEvent.custom_pricing.pricing_type == 'fixed'">
                            <vs-input readonly :label="$t('customPricings.basePrice')"  v-model="selectedPricing.originalEvent.price" class="w-full px-0 py-2" />
                        </div>
                        <div class="vx-col w-full flex flex-wrap mt-2" v-else>
                            <vs-input readonly v-for="input in guests_inputs" :key="input.key" :label="input.name" v-model="selectedPricing.originalEvent.custom_pricing[input.key]" class="w-full px-0 py-2" />
                            
                        </div>
                    </div>
                </vs-collapse-item>
                
                <vs-collapse-item icon-arrow="arrow_downward" v-for="pricing in unappliedCustomPricings" :key="pricing.id">
                    <div slot="header">
                        <div class="vx-col p-0 w-full flex flex-wrap justify-between pr-5">
                            <h3 class="text-danger line-through">{{pricing.title}}</h3>
                            <h3 class="text-danger line-through">{{pricing.base_price}} {{$t("SAR")}}</h3>
                        </div>
                        
                    </div>
                    <div class="flex flex-wrap mb-5" v-if="pricing">

                        <div class="vx-col p-0 w-full flex flex-wrap justify-between">
                            <h4>{{$t('customPricings.pricingType'+pricing.pricing_type)}}</h4>
                            <vs-chip class="font-medium truncate" color="danger">{{$t('customPricings.notAppliedPricing')}}</vs-chip>
                        </div>
                        <div class="vx-col w-full flex flex-wrap mt-2" v-if="pricing.pricing_type == 'fixed'">
                            <vs-input readonly :label="$t('customPricings.basePrice')"  v-model="pricing.base_price" class="w-full px-0 py-2" />
                        </div>
                        <div class="vx-col w-full flex flex-wrap mt-2" v-else>
                            <vs-input readonly v-for="input in guests_inputs" :key="input.key" :label="input.name" v-model="pricing[input.key]" class="w-full px-0 py-2" />
                            
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </vs-popup>
        <div class="vx-row">
            <div class="vx-col w-full" color="red">
                <vx-card class="w-full mb-5 p-3 pb-0 mr-9">
                    <div class="vx-row">
                        <h2>{{$t('customPricings.calendar.formTitle')}}</h2>
                        
                    </div>
                    <div class="vx-row mt-2">
                        <h6>{{$t('customPricings.calendar.formDescription')}}</h6>
                    </div>

                    <vs-divider></vs-divider>
                    <div class="w-full flex flex-wrap"> 
                        <vs-input v-for="(guests_input) in selectedEventType.guests_inputs" :key="guests_input.key" v-model="PricingValues[guests_input.key]" class="pt-1 mb-2 pr-2" :label="guests_input.name" type="number" autocomplete="off"/>
                        
                        <div class="px-1 pt-2 w-1/4">
                            <vs-button icon="search" class="mt-5" @click="submitPricingValues">{{ $t("customPricings.calendar.update")}}</vs-button>
                        </div> 
                        
                    </div>
                </vx-card>
            </div>
        </div>
        <div class="vx-card no-scroll-content" id="simple-calendar-app">
            <calendar-view
                :events="calendarEvents"
                ref="calendar"
                :displayPeriodUom="calendarView"
                :show-date="showDate"
                :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
                eventBorderHeight="0px"
                eventContentHeight="1.65rem"
                class="theme-default"
                @click-event="previewPricing"
            >

                <div slot="header" class="mb-4">

                    <div class="vx-row no-gutter" id="calendar">

                        

                        <!-- Current Month -->
                        <div class="vx-col w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
                            <div class="flex items-center">
                                <feather-icon
                                :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                                @click="updateMonth(-1)"
                                svgClasses="w-5 h-5 m-1"
                                class="cursor-pointer bg-primary text-white rounded-full" />

                                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                                <feather-icon
                                :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                                @click="updateMonth(1)"
                                svgClasses="w-5 h-5 m-1"
                                class="cursor-pointer bg-primary text-white rounded-full" />
                            </div>
                        </div>
                        
                        
                    </div>

                    
                </div>


            </calendar-view>
        </div>
    </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
import moment from 'moment'
import axios from 'axios'
import vSelect from 'vue-select'

export default {
    components: {
        CalendarView,
        CalendarViewHeader,
        vSelect
    },
    props: {
        
    },
    data () {
        return {
            calendarView: 'month',
            showDate: new Date(),
            PricingValues: {
                men_count: 10,
                women_count: 10,
                people_count: 0
            },
            selectedPricing: {},
            unappliedCustomPricings: [],
            previewPricingActive: false,
            // selectedEventType: this.eventTypesList[0],
            selectedEventType: {},
            guests_inputs: [],
            guestsInputStyle: 'mt-5 w-1/2',
        }
    },
    computed: {
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        bookingOption () {  // get the clicked booking option
            return this.$store.state.dataList.bookingOption;
        },
        calendarEvents () {
            return [...this.$store.getters.customPricingsInCalendar];
        },
        windowWidth () {
            return this.$store.state.windowWidth
        },
        eventTypesList () {
            return this.bookingOption.event_types
        },
    },
    created() {
        this.$store.dispatch('dataList/fetchEventTypesList')
        this.$store.dispatch('dataList/getBookingOption', {venue: this.currentVenueId, option_id: this.$route.params.option_id});
        
        this.submitPricingValues()
        
    },
    mounted () {
        const daysHeader = document.getElementsByClassName('cv-header-days')[0]
        daysHeader.style.setProperty('direction','ltr','important')

        this.$root.$on('fetchCalendarData', () => {
            this.submitPricingValues();
        });
    },
    methods: {
        updateMonth (val) {
            this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
            const date = moment(this.showDate).format('yyyy-MM-DD')
            this.submitPricingValues();
        },
        submitPricingValues() {
            this.$store.dispatch('loader/loaderOn')
            let request = {
                start_date: moment(this.showDate).startOf('month').format('yyyy-MM-DD'),
                end_date: moment(this.showDate).endOf('month').format('yyyy-MM-DD'),
                men_count: this.PricingValues.men_count,
                women_count: this.PricingValues.women_count,
                people_count: this.PricingValues.people_count
            }
            this.$store.dispatch('customPricings/getCustomPricingsForCalendar', {venueId: this.currentVenueId, optionId: this.$route.params.option_id, request})
            .finally(() => this.$store.dispatch('loader/loaderOff'));
        },
        getUnappliedPricings() {
            this.$store.dispatch('loader/loaderOn')

            let request = {
                date: this.selectedPricing.originalEvent.startDate,
                men_count: this.PricingValues.men_count,
                women_count: this.PricingValues.women_count,
                people_count: this.PricingValues.people_count
            }
            let _this = this;

            this.$store.dispatch('customPricings/getUnappliedPricings', {venueId: this.currentVenueId, optionId: this.$route.params.option_id, request})
            .then((response) => {
                this.unappliedCustomPricings = response.data.unappliedCustomPricings;
                this.modifyGuestsInputs();
            })
            .catch((error) => { console.log('get analysis errer', error) })
            .finally(() => {
                this.$store.dispatch('loader/loaderOff')
            })
        },
        previewPricing(data) {
            this.selectedPricing = data;
            this.previewPricingActive = true;
            this.getUnappliedPricings();
        },
        modifyGuestsInputs() { // this function is to change the keys to (man_price, women_price, people_price)
            this.guests_inputs = JSON.parse(JSON.stringify(this.eventTypesList[0].guests_inputs));
            this.guests_inputs.forEach((input, index) => {
                let key = '';
                let name = '';
                if(input.key == 'men_count') {
                    name = input.name == 'عدد الرجال' ? this.$t('customPricings.manPrice') : input.name;
                    key = 'man_price';
                } else if(input.key == 'women_count') {
                    name = input.name == 'عدد النساء' ? this.$t('customPricings.womanPrice') : input.name;
                    key = 'woman_price';
                } else {
                    name = input.name == 'عدد الأشخاص' ? this.$t('customPricings.peoplePrice') : input.name;
                    key = 'people_price';
                }
                this.guests_inputs[index] = {
                    key: key,
                    name: name
                }
            });
            
        }
    },
    watch: {
        eventTypesList: function(val) {
            this.selectedEventType = val[0];
        }
    }
}
</script>

<style lang="scss">
.cv-header-days{
    direction: ltr !important;
  }

  [dir=rtl] .cv-header-days{
    direction: ltr !important; 
  }
  
  @import "@/assets/scss/vuexy/apps/simple-calendar.scss";

  #app .cv-header-days{
    direction: ltr !important;
  }

.line-through {
  text-decoration: line-through;
}

</style>

