<template>
  <div>
    <vs-popup class="holamundo"  :title="isEdit ? $t('customPricings.edit') + ' | ' + pricingToEdit.title : $t('customPricings.add')" :active.sync="pricingFormActive" @close="closePopup">
        <div class="flex flex-wrap pt-0" v-if="pricingToEdit">

            <vs-input :label="$t('customPricings.title')" v-model="pricingToEdit.title" class="mt-5 w-full" autocomplete="off"/>


            <ul class="leftx mt-10 mb-5 w-full pr-2">
                <li>
                    <vs-checkbox color="warning" v-model="isSeasonal"><h6>{{$t('customPricings.pricingIsSeasonal')}}</h6></vs-checkbox>
                </li>
            </ul>

                <v-date-picker
                    class="w-full"
                    v-model="bookingsDateRange"
                    mode="date"
                    is-range
                    color="orange"
                    :masks="dateFormat"
                    v-if="isSeasonal"
                >
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                      <div class="w-full flex flex-wrap justify-between">
                          <vs-input
                            :label="$t('customPricings.startDate')"
                            class="flex-grow py-1 bg-gray-100 border rounded w-1/2"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            icon="today"
                          />

                          <vs-input
                            :label="$t('customPricings.endDate')"
                            class="flex-grow py-1 bg-gray-100 border rounded w-1/2"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            icon="event"
                          />
                      </div>
                    </template>
                </v-date-picker>

            <ul class="leftx mt-10 w-full flex flex-wrap">
                <li class="modelx">
                    <h5 class="mb-5 mr-5">{{$t('customPricings.pricingType')}}:</h5>
                </li>
                <li class="mx-2">
                    <vs-radio v-model="pricingToEdit.pricing_type" vs-value="fixed">{{$t('customPricings.fixed')}}</vs-radio>
                </li>
                <li class="mx-2">
                    <vs-radio v-model="pricingToEdit.pricing_type" vs-value="variable">{{$t('customPricings.variable')}}</vs-radio>
                </li>
            </ul>


            <vs-input type="number" :label="$t('customPricings.basePrice')" v-if="pricingToEdit.pricing_type == 'fixed' && isSeasonal" v-model="pricingToEdit.base_price" class="mt-5 w-full" autocomplete="off"/>
            <div class="w-full flex flex-wrap" v-if="pricingToEdit.pricing_type == 'variable'">
                <vs-input v-for="(guests_input) in guests_inputs" :key="guests_input.key" v-model="pricingToEdit[guests_input.key]" :class="guestsInputStyle" :label="guests_input.name" type="number" autocomplete="off"/>
            </div>
            <h5 class="mt-10">{{$t('customPricings.daysPricings')}}:</h5>
            <div class="mt-2 w-full flex flex-wrap justify-between" v-for="(day, index) in days" :key="index">
                <div class="pt-5 w-1/4">
                    <label class="v-select-label">{{$t('customPricings.day')}}</label>
                    <v-select
                        class="v-select-background"
                        v-model="day.pivot.day_id"
                        :options="daysList"
                        label="arabic_name"
                        :reduce="arabic_name => arabic_name.id"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                </div>

                <div class="w-2/3" v-if="pricingToEdit.pricing_type == 'fixed'">
                    <vs-input type="number" :label="$t('customPricings.basePrice')" v-model="day.pivot.base_price" class="mt-5 w-full" autocomplete="off"/>
                </div>
                <div class="w-2/3 flex flex-wrap justify-around" v-if="pricingToEdit.pricing_type == 'variable'">
                    <vs-input v-for="(guests_input) in guests_inputs" :key="guests_input.key" v-model="day.pivot[guests_input.key]" :class="guestsInputStyle" :label="guests_input.name" type="number" autocomplete="off"/>
                </div>

                <div class="mt-1">
                    <vs-button class="mt-10" icon="delete" color="danger" @click="removeInput(index)"></vs-button>
                </div>
            </div>
            <div class="w-full flex flex-wrap justify-end">
                <vs-button class="mt-12 w-1/12" icon="add" @click="addInput"></vs-button>
            </div>


            <div class="w-full mt-10">
                <vs-button v-if="!isEdit" icon="done" @click="submitPricingForm">{{ $t("Add")}}</vs-button>
                <vs-button v-else icon="edit" @click="submitPricingForm">{{ $t("edit")}}</vs-button>
            </div>
        </div>
    </vs-popup>
  </div>
</template>

<script>
import { en, ar } from 'vuejs-datepicker/src/locale'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
      Datepicker,
      vSelect,
  },
  props: {
    pricingFormActive: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    currentPricing: {
        type: Object,
        default: () => {}
    }
  },
  data () {
    return {
        pricingToEdit: {},
        langAr: ar,
        langEn: en,
        isSeasonal: true,
        days: [],
        validated: true,
        bookingsDateRange: {
            start: moment().startOf('month').toDate(),
            end: moment().endOf('month').toDate(),
        },
        dateFormat: {
            input: 'YYYY-MM-DD'
        },
        selectedEventType: {},
        guests_inputs: [],
        guestsInputStyle: '',

    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    bookingOption () {  // get the clicked booking option
        return this.$store.state.dataList.bookingOption;
    },
    daysList() {
        return this.$store.state.general.daysList
    },
    eventTypesList () {
        return this.bookingOption.event_types
    },
  },
  created () {
      this.$store.dispatch('dataList/getBookingOption', {venue: this.currentVenueId, option_id: this.$route.params.option_id});
      this.$store.dispatch('general/getDaysList');

  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },

    setupBookingDateFormats(){
      // setup the dates formats
      if(this.isSeasonal) {
          this.pricingToEdit.start_date = moment(this.bookingsDateRange.start).format('yyyy-MM-DD')
          this.pricingToEdit.end_date = moment(this.bookingsDateRange.end).format('yyyy-MM-DD')
      } else {
          this.pricingToEdit.start_date = null
          this.pricingToEdit.end_date = null
      }

    },
    validateForm() {
        if(!this.pricingToEdit.title) {
            this.validated = false;
            this.$vs.notify({
                color:'danger',
                title: this.$t('error'),
                text: 'الرجاء ادخال عنوان التسعيرة'
            });
            this.$store.dispatch('loader/loaderOff');
            return;
        }
        this.validated = true;
        this.validateDays();
    },
    validateDays() {
        let pricing_type = this.pricingToEdit.pricing_type;
        this.days.forEach(day => {
            if(!day.pivot.day_id) {
                this.validated = false;
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: this.$t('customPricings.daysValidationError')
                });
                this.$store.dispatch('loader/loaderOff');
                return;
            }
            if(pricing_type == 'fixed' && !day.pivot.base_price) {
                this.validated = false;
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: this.$t('customPricings.daysValidationError')
                });
                this.$store.dispatch('loader/loaderOff');
                return;
            }
            if(pricing_type == 'variable' && !day.pivot.man_price && !day.pivot.woman_price && !day.pivot.prople_price) {
                this.validated = false;
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: this.$t('customPricings.daysValidationError')
                });
                this.$store.dispatch('loader/loaderOff');
                return;
            }
            this.validated = true;

        });

    },
    submitPricingForm() {

        this.$store.dispatch('loader/loaderOn')
        this.setupBookingDateFormats();
        this.validateForm();
        if(!this.validated)
            return;
        // send the request
        let action = this.isEdit ? 'customPricings/updateCustomPricing' : 'customPricings/addCustomPricing';
        let request = this.pricingToEdit;
        request.days = this.days;
        this.$store.dispatch(action, {venueId: this.currentVenueId, optionId: this.bookingOption.id, pricingId: this.pricingToEdit.id ? this.pricingToEdit.id : null, request: request})
            .then(response => {
            this.$vs.notify({
                color:'success',
                title: this.isEdit ? this.$t('updateDone') : this.$t('addedDone'),
                text: response.data.success_message
            })
            this.$emit('closePopup');
            this.$root.$emit('fetchCalendarData');
            })
            .catch(function(error){
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: error || this.isEdit ? 'عذرًا لم نستطع انشاء حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا' : 'عذرًا لم نستطع تعديل الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
                })
            console.error(error)
            this.error = error;
            }.bind(this))
            .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    addInput() {
        let day = {
            pivot: {
                day_id: null,
                base_price: null,
                man_price: null,
                woman_price: null,
                people_price: null,
            }
        }
        this.days.push(day)
    },
    removeInput(index) {
        this.days.splice(index,1)
    },
    modifyGuestsInputs() {
        this.guests_inputs = JSON.parse(JSON.stringify(this.eventTypesList[0].guests_inputs));
        this.guests_inputs.forEach((input, index) => {
            let key = '';
            let name = '';
            if(input.key == 'men_count') {
                name = input.name == 'عدد الرجال' ? this.$t('customPricings.manPrice') : input.name;
                key = 'man_price';
            } else if(input.key == 'women_count') {
                name = input.name == 'عدد النساء' ? this.$t('customPricings.womanPrice') : input.name;
                key = 'woman_price';
            } else {
                name = input.name == 'عدد الأشخاص' ? this.$t('customPricings.peoplePrice') : input.name;
                key = 'people_price';
            }
            this.guests_inputs[index] = {
                key: key,
                name: name
            }
        });
        if(this.guests_inputs.length == 1) {
            this.guestsInputStyle = 'mt-5 w-full'
        } else {
            this.guestsInputStyle = 'mt-5 pl-1 w-1/'+this.guests_inputs.length
        }
    }
  },
  watch: {
    currentPricing: function (val) {
        val = JSON.parse(JSON.stringify(val));
        this.pricingToEdit = val;
        this.days = val.days;
        this.bookingsDateRange = {
            start : val.start_date,
            end : val.end_date,
        }
        this.isSeasonal = val.start_date != null;
        if(this.isEdit)
            this.selectedEventType = this.eventTypesList.find(element => val.event_type_id == element.id);
        this.modifyGuestsInputs();
    },
    eventTypesList: function(val) {
        if(!this.isEdit)
            this.selectedEventType = val[0];
        this.modifyGuestsInputs();
    }
  }

}
</script>

<style>

</style>
