<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="scaffold">
    
    <!-- CUSTOM PRICINGS TABLE -->
    <custom-pricings-table />
    
    <!-- CUSTOM PRICINGS CALENDAR -->
    <custom-pricings-calendar />
  </div>
</template>

<script>

import CustomPricingsTable from './CustomPricingsTable.vue'
import CustomPricingsCalendar from './CustomPricingsCalendar.vue'
export default {
  data () {
    return {
      
    }
  },
  components: {
    CustomPricingsTable,
    CustomPricingsCalendar,
  },
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#scaffold {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}

</style>
