<template>
    <div>
        <!-- SHOW BOOKING POPUP -->


        <!-- <pricing-form-pop-up
        :currentPricing="currentPricing"
        :pricingFormActive="pricingFormActive"
        :isEdit="isEdit"
        @closePopup="closePopup"/> -->

        <new-pricing-form-pop-up
        :currentPricing="currentPricing"
        :pricingFormActive="pricingFormActive"
        :isEdit="isEdit"
        :isSeasonal="isSeasonal"
        :option="bookingOption"
        @closePopup="closePopup"
        />

        <vs-prompt :title="$t('customPricings.deletePricing')" class="export-options" @accept="deletePricing(currentPricing)" :accept-text="$t('home.yes')" :cancel-text="$t('close')" :active.sync="deletePromptActive">
            <p v-if="currentPricing">{{$t('customPricings.areYouSureYouWantToDelete') +' ' + currentPricing.title + '?'}}</p>
        </vs-prompt>

        <div class="vx-row justify-between">
            <div class="vx-col mb-10">
                <vs-breadcrumb
                    :items="
                    [
                        {
                            title: onBoardingStep ? $t('pages.onBoarding') : $t('pages.PricingsBookingOptions'),
                            url: onBoardingStep ? `/#/${this.currentVenueId}/on-boarding` : `/#/${this.currentVenueId}/pricings-booking-options`,
                        },
                        {
                            title: bookingOption.name,
                            active: true
                        },
                    ]"

                ></vs-breadcrumb>
            </div>
            <div class="vx-col mb-10 " v-if="onBoardingStep">
                <vs-button size="small" type="border" icon-pack="FA5" icon="fas fa-undo" :to="{ name: 'on-boarding'}">{{$t('onBoarding.backToOnboarding')}}</vs-button>
            </div>

        </div>

        <div class="vx-row">
            <div class="vx-col w-full mb-10 mx-0">
                <vx-card :title="$t('customPricings.customPricingsFor') + ' ' +bookingOption.name">
                    <vs-tabs :value="activeTab">
                        <vs-tab :label="$t('customPricings.daysPricings')">
                            <div>
                                <vs-table
                                    class="mb-5"
                                    :sst="true"
                                    ref="table"
                                    :data="nonSeasonalCustomPricings"
                                    v-model="selected"
                                    :pagination="isPagination"
                                    :search="isSearch"
                                >
                                    <div slot="header" class="w-full mb-5 flex justify-end" v-if="nonSeasonalCustomPricings.length == 0">
                                        <vs-button icon="add" @click="addPricing(false)" >{{ $t("Add")}}</vs-button>
                                    </div>

                                    <template  slot="thead">
                                        <template>
                                            <vs-th v-for="(col, index) in daysColumnsTitles" :key="index" :sort-key="col.sortKey">
                                            <p class="table-head font-medium truncate">{{ col.title }}</p>
                                            </vs-th>
                                        </template>
                                    </template>



                                    <template slot-scope="{data}">
                                        <tbody>
                                            <template>
                                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                                <vs-td>
                                                    <p class="product-no font-medium truncate">{{ tr.title }}</p>
                                                </vs-td>

                                                <vs-td class="whitespace-no-wrap">
                                                    <span class="flex flex-row whitespace-no-wrap">
                                                        <vx-tooltip :text="$t('buttons.edit')" >
                                                            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current mr-2" @click.stop="editPricing(tr, false)" />
                                                        </vx-tooltip>
                                                        <!-- <vx-tooltip :text="$t('buttons.delete')" >
                                                            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="opentDeletePrompt(tr)" />
                                                        </vx-tooltip> -->
                                                    </span>
                                                </vs-td>

                                                </vs-tr>
                                            </template>

                                        </tbody>
                                    </template>

                                </vs-table>
                            </div>
                        </vs-tab>
                        <vs-tab :label="$t('customPricings.seasonalPricings')">
                            <div>
                                <vs-table
                                    class="mb-5"
                                    :sst="true"
                                    ref="table"
                                    :data="seasonalCustomPricings"
                                    v-model="selected"
                                    :pagination="isPagination"
                                    :search="isSearch"
                                >
                                    <div slot="header" class="w-full mb-5 flex justify-end">
                                        <vs-button icon="add" @click="addPricing(true)">{{ $t("Add")}}</vs-button>
                                    </div>

                                    <template  slot="thead">
                                        <template>
                                            <vs-th v-for="(col, index) in seasonalColumnsTitles" :key="index" :sort-key="col.sortKey">
                                            <p class="table-head font-medium truncate">{{ col.title }}</p>
                                            </vs-th>
                                        </template>
                                    </template>



                                    <template slot-scope="{data}">
                                        <tbody>
                                            <template>
                                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                                <vs-td>
                                                    <p class="product-no font-medium truncate">{{ tr.title }}</p>
                                                </vs-td>

                                                <vs-td>
                                                    <p class="product-name font-medium truncate">{{ tr.start_date }}</p>
                                                </vs-td>

                                                <vs-td>
                                                    <p class="customer-phone">{{ tr.end_date }}</p>
                                                </vs-td>

                                                <vs-td class="whitespace-no-wrap">
                                                    <span class="flex flex-row whitespace-no-wrap">
                                                        <vx-tooltip :text="$t('buttons.edit')" >
                                                            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current mr-2" @click.stop="editPricing(tr, true)" />
                                                        </vx-tooltip>
                                                        <vx-tooltip :text="$t('buttons.delete')" >
                                                            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="opentDeletePrompt(tr)" />
                                                        </vx-tooltip>
                                                    </span>
                                                </vs-td>

                                                </vs-tr>
                                            </template>

                                        </tbody>
                                    </template>

                                </vs-table>
                            </div>
                        </vs-tab>
                    </vs-tabs>

                </vx-card>
            </div>
        </div>


    </div>
</template>

<script>
import PricingFormPopUp from './PricingFormPopUp'
import NewPricingFormPopUp from './NewPricingFormPopUp'
export default {
    components: {
        PricingFormPopUp,
        NewPricingFormPopUp
    },
    props: {
        isSearch: {
            type: Boolean,
            default: false
        },
        isPagination: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: [],
            daysColumnsTitles : [
                {title: this.$t('customPricings.title'), key: "title"},
            ],
            seasonalColumnsTitles : [
                {title: this.$t('customPricings.title'), key: "title"},
                {title: this.$t('customPricings.startDate'), key: "start_date"},
                {title: this.$t('customPricings.endDate'), key: "end_date"},
            ],

            pricingFormActive: false,
            currentPricing: null,
            isEdit: false,
            isSeasonal: false,
            deletePromptActive: false,
            customPricingsInTable: [],
        }
    },
    computed: {
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        customPricings () { // get list of custom pricings from store
            return this.$store.getters.customPricings;
        },
        bookingOption () {  // get the clicked booking option
            return this.$store.state.dataList.bookingOption;
        },
        daysList() {
            let days = this.$store.state.general.daysList;
            if(days.length)
                days.forEach((day, index) => {
                    days[index].pivot = {
                        day_id: day.id,
                        base_price: null,
                        man_price: null,
                        woman_price: null,
                        people_price: null,
                        is_visible: false,
                    }
                });
            return days
        },
        seasonalCustomPricings () {
            return this.$store.state.customPricings.seasonalCustomPricings;

        },
        nonSeasonalCustomPricings () {
            if(this.$store.state.customPricings.nonSeasonalCustomPricing.length > 0) {
                return this.$store.state.customPricings.nonSeasonalCustomPricing;
            } else {
                let placeHolderNonSeasonalPricing = JSON.parse(JSON.stringify(this.$store.state.customPricings.nonSeasonalCustomPricing));
                placeHolderNonSeasonalPricing.push({
                        title: 'تسعيرة الأيام',
                        pricing_type: this.bookingOption.pricing_type,
                        days: this.daysList
                    });
                return placeHolderNonSeasonalPricing;
            }
        },
        onBoardingStep() {
            return localStorage.getItem('onboardingActiveTab');
        },
        activeTab() {
            let hash = this.$route.hash;
            return hash == '#seasonalPricings' ? 1 : 0
        }
    },
    created() {
        this.$store.dispatch('dataList/getBookingOption', {venue: this.currentVenueId, option_id: this.$route.params.option_id});
        this.$store.dispatch('general/getDaysList');
        this.getCustomPricingsApi();
    },
    methods: {
        addPricing (isSeasonal) {
            this.currentPricing = {
                pricing_type: this.bookingOption.pricing_type,
                days: this.daysList
            };
            this.isEdit = false;
            this.isSeasonal = isSeasonal;
            this.pricingFormActive = true;
        },
        editPricing (data, isSeasonal) {
            this.currentPricing = data;
            this.isEdit = true;
            this.isSeasonal = isSeasonal;
            this.pricingFormActive = true;
        },
        opentDeletePrompt(data) {
            this.currentPricing = data;
            this.deletePromptActive = true
        },
        deletePricing (data) {

            this.$store.dispatch('loader/loaderOn')

            this.$store.dispatch('customPricings/deleteCustomPricing', {venueId: this.currentVenueId, optionId: this.$route.params.option_id, pricingId: data.id})
            .then(response => {
                this.$vs.notify({
                    color:'success',
                    title: this.$t('deleteDone'),
                    text: response.data.success_message
                })
                this.$emit('closePopup');
                this.$root.$emit('fetchCalendarData');
                })
            .catch(function(error){
                this.$vs.notify({
                    color:'danger',
                    title: this.$t('error'),
                    text: error || this.isEdit ? 'عذرًا لم نستطع انشاء حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا' : 'عذرًا لم نستطع تعديل الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
                })
                console.error(error)
                this.error = error;
            }.bind(this))
            .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
        },
        closePopup () {
            this.pricingFormActive = false;
        },
        getCustomPricingsApi() {
            this.$store.dispatch('customPricings/getNonSeasonalCustomPricing', {venueId: this.currentVenueId, optionId: this.$route.params.option_id});
            this.$store.dispatch('customPricings/getSeasonalCustomPricings', {venueId: this.currentVenueId, optionId: this.$route.params.option_id});
        }

    },
    watch: {
        customPricings: function() {
            this.customPricingsInTable = this.customPricings;
        }
    }
}
</script>

<style>

</style>

